import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import SeasonYear from '../../components/SeasonYear';

import pic11 from '../../assets/images/publication-banners/kurt_bestor.jpg';

const KurtBestor = (props) => (
  <Layout>
    <Helmet>
      <title>Kurt Bestor Christmas - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="Advertising and event information for A Kurt Bestor’s Christmas playbill. Please contact Mills Publishing for availability and rates."
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Kurt Bestor Christmas</h1>
          </header>
          <span className="image main">
            <img src={pic11} alt="" />
          </span>
          <p className="box">
            This annual event is the perfect way to reach that holiday crowd.
            Time and time again, Kurt Bestor pulls through with some of the
            finest Christmas music to grace the stage. Advertisers will be
            exposed to an exclusive audience during the peak of the Holiday
            season.
          </p>
          <ul className="actions">
            <li>
              <a href="http://www.kurtbestor.com/" className="button special">
                <i className="icon fa-globe"> </i>Kurt Bestor Christmas Website
              </a>
            </li>
          </ul>
          <h2>Kurt Bestor Christmas {new Date().getFullYear()} Information</h2>
          <p>
            <em>
              Dates are subject to change. Please contact us if you have any
              questions.
            </em>
          </p>
          <div className="grid-wrapper">
            <div className="col-12 box">
              <h3>December 5–8, 2024</h3>
              <p>
                <strong>Circulation: 10,000</strong> <br />
                <br />
                <br />
                <h5>Advertising Information</h5>
                <section className="box">
                  File Ready Deadline: 10/31/24 <br />
                  Circulation: 11/17/24
                </section>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default KurtBestor;
